import React, { useRef, useEffect } from 'react'
import { useMutation} from 'react-apollo'
import gql from 'graphql-tag'
import { useNavigate, useParams} from 'react-router-dom'
import Modal from '../../../../Modal'
import { CREATE_SUPPORT_PAGE_SECTION_MUTATION } from '../Query'
import { Mixpanel } from '../../../../../Common/Mixpanel'

const NewSection = () => {
    const { supportPageId } = useParams();
    let navigate = useNavigate()
    const Section = useRef<HTMLInputElement>();
    const [createSection, { data }] = useMutation(CREATE_SUPPORT_PAGE_SECTION_MUTATION);

    const save = () => {
        createSection({ variables: {id: supportPageId , sectionType: Section.current.value}})
    }

    const cancel = () => {
        navigate(-1)
    }

    useEffect(() => {
        if(data && data.createSupportPageSection) {
            navigate(`/admin/section/${data.createSupportPageSection.id}`)
            Mixpanel.track("Created Section", data.createSupportPageSection) 
        }
    }, [data, navigate]);

    return (
        <Modal title="New Section" save={save} cancel={cancel}>
            <div className="my-4 text-gray-600 text-lg leading-relaxed">
                <input aria-label="Email address" name="email" type="email" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Section type: " ref={Section}/>
            </div>
        </Modal>
    )
}

export default NewSection
