import React, {useState} from 'react'
import { useQuery, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import DateTimePicker from 'react-datetime-picker'
import { useParams } from 'react-router-dom'
import { Video } from '../../types'
import { VideoUpload } from './Upload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash} from '@fortawesome/free-solid-svg-icons'
import { Mixpanel } from '../../Common/Mixpanel'

export const VIDEOEDIT_QUERY = gql`
query VideoEdit($id:ID!) {
    video(id:$id) {
        id
        title
        createdAt
        embedUrl
        publicPoster
        publicUrl
        metadata
        title
        hasFile
        validUntil
        filePresent
        versions {
            hasFile
            filePresent
            publicUrl
            mimeType
            label
            resolution
        }
        channel {
            id
            title
        }
        player {
            id
            title
        }
        tags {
            id
            name
        }
    }
    channels(limit:20) {
        items {
            id
            title
        }
    }
    players(limit:20){
        items {
            id
            title
        }
    }
}
`

export const VIDEOEDIT_MUTATION = gql`
mutation EditVideo($id:String!, $title:String, $channelId:String, $playerId:String, $tags:[String!], $validUntil: Date, $metadata:JSON, $uploadedVideo: UploadInput) {
  updateVideo(options: {id: $id, title:$title, channelId:$channelId, playerId:$playerId, tags:$tags, validUntil:$validUntil, metadata:$metadata, uploadedVideo: $uploadedVideo}) {
    id
    title
    tags {
      id
      name
    }
    channel {
      id
      title
    }
    player {
      id
      title
    }
    validUntil
    metadata
  }
}
`

const TextInput = ({id, label, defaultValue, onChange}) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <input id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={defaultValue} onChange={(evt) => { onChange && onChange(evt.currentTarget.value)}} />
                </div>
            </div>
        </div>
    )
}

const SelectInput = ({id, label, defaultValue, options, allowEmpty, onChange, emptyOption = '[none]'}) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <select id={id} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"  defaultValue={defaultValue} onChange={(evt) => { onChange && onChange(evt.currentTarget.value); } }>
                        { allowEmpty ? <option value={undefined} key="empty">{emptyOption}</option> : <></> }
                        {options.map((item) => {
                            return (
                                <option value={item.id} key={item.id}>{item.title}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}

const DateTimeInput = ({id, label, defaultValue, onChange}) => {
    const [value, setValue] = useState(defaultValue)

    const setNow = () => {
        const newValue = new Date
        setValue(newValue)
        onChange && onChange(newValue)
    }

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <DateTimePicker value={value} onChange={(evt) => {setValue(evt); onChange && onChange(evt)} } locale="da-DK" className="p-0" /><a className="ml-4 text-xs" href="#" onClick={setNow}>Now</a>
                </div>
            </div>
        </div>
    )
}

const TagEntry = ({onChange, deletePrevious}) => {
    const [value, setValue] = useState("")

    const change = (evt) => {
        setValue(evt.currentTarget.value)
    }

    const keyDown = (evt : React.KeyboardEvent<HTMLInputElement>) => {
        if(evt.key == "Enter") {
            onChange(value)
            setValue("")
        }

        if(evt.key == "Backspace") {
            if(value=="") deletePrevious();
        }

        if(evt.key == "Escape") {
            setValue("")
        }
    }

    return (
        <input onChange={change} onKeyDown={keyDown} value={value} />
    )
}

const TagsInput = ({id, label, tags, onChange}) => {
    const addTag = (name) => {
        if(tags.includes(name)) return;
        onChange([...tags, {name}])
    }

    const removeTag = (index) => {
        const newList = [...tags]
        newList.splice(index, 1)
        onChange(newList)
    }

    const deletePrevious = () => {
        if(tags.length > 0) removeTag(tags.length - 1)
    }

    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">{label}</label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                    <div className="flex">
                        {tags.map((tag, i) => {
                        return (
                            <div className="px-3 py-2 mx-1 relative bg-brand-500 hover:bg-brand-300 text-white rounded-full" key={tag.name}>
                                <span className="pr-4">{tag.name}</span>
                                <FontAwesomeIcon icon={faTrash} className="w-2 h-2 absolute top-0 right-0 mr-2 mt-1 hover:text-danger" onClick={() => removeTag(i)} />
                            </div>
                        )
                    })}
                    <TagEntry onChange={(tag) => addTag(tag)} deletePrevious={deletePrevious} />
                </div>
                </div>
            </div>
        </div>
    )
}

type UploadVars = {
    id: string;
    filename: string;
}


const Edit = () => {
    let params = useParams()
    const [video, setVideo] = useState(undefined as Video)
    const [uploadVars, setUploadVars] = useState(undefined as UploadVars)

    const fetchMutationVars = (source, uploadVars) => {
        const id = params.videoId
        const out = {
            id: id,
            ...source
        }
        out['tags'] = out['tags'].map((tag) => tag.name)

        if(uploadVars) {
            out['uploadedVideo'] = uploadVars
        }
        return out
    }


    const setField = (fieldName: string, value?: any) => {
        if (value === undefined) {
            return function(value: any) {
                setVideo(
                    {
                        ...video,
                        [fieldName]: value
                    }
                )
            }
        } else {
            setVideo(
                {
                    ...video,
                    [fieldName]: value
                }
            )
        }
    }

    const onUpload = (id: string, filename: string) => {
        setUploadVars({
            id,
            filename
        })
    }


    const vars = {variables:{id: params.videoId}}

    const { loading, data } = useQuery(VIDEOEDIT_QUERY, vars);
    const [saveVideo, saveResult] = useMutation(VIDEOEDIT_MUTATION);

    const save = () => {
        const vars = fetchMutationVars(video, uploadVars)
        const result = saveVideo({ variables: vars })
        result.then((done) => {
            Mixpanel.track("Saved video", vars) 
            console.log(done)
        }).catch(v => console.error(v))
    }

    if(!video && data) {
        setVideo(data.video)
    }


    if(loading) return <span />
    if(!video) return <span />

        return (
                    <div className="w-full">
                        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                                    Replace video
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <div className="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                                        {uploadVars ? <span>{uploadVars.filename}</span> :

                                        <VideoUpload onUpload={onUpload} id={video.id} />
                                    }
                                    </div>
                                </div>
                            </div>
                            <TextInput id="title" label="Title" defaultValue={video.title} onChange={setField('title')} />
                            <SelectInput id="channel" label="Channel" defaultValue={video.channel?.id} options={data.channels.items} allowEmpty={false} onChange={setField('channelId')} />
                            <SelectInput id="player" label="Default Player" defaultValue={video.player?.id} options={data.players.items} allowEmpty={true} onChange={setField('playerId')} emptyOption="[from channel]"/>
                            <DateTimeInput id="validUntil" label="Valid Until" defaultValue={video.validUntil}  onChange={setField('validUntil')} />
                            <TagsInput id="tags" label="Tags" tags={video.tags}  onChange={setField('tags')} />
                          <button onClick={save} className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline">{saveResult && saveResult.loading ? "Saving...." : "Save"}</button>
                        </div>
                    </div>

        )
}


export default Edit
