import gql from 'graphql-tag'

export const SUPPORT_PAGE_QUESTIONS_QUERY = gql`
query SupportQuery($limit: Int, $supportPageid: ID, $search: String, $page: Int, $sortBy:SupportPageQuestionSortingField, $sortDirection: SortingDirection, $searchType: SupportPageQuestionSearch) {
  accounts(limit: $limit, page: 1) {
    items {
      id
      title
      supportPages {
        id
        title
      }
    }
  }
  supportPage(id: $supportPageid) {
    questions(search: $search, limit: $limit, page: $page, sortBy: $sortBy, sortDirection: $sortDirection, searchType: $searchType) {
       	currentPage
        totalPages
        previousPage
        nextPage
      items {
        id
        question
        answer
        topic
        mediaUrl
        mediaType
        question
        createdAt
        updatedAt
      }
    }
  }
}
 
`

export const SUPPORT_PAGE_SECTIONS_QUERY = gql`
query SupportQuery($limit: Int, $supportPageid: ID, $search: String, $page: Int, $sortBy:SupportPageSectionSortingField, $sortDirection: SortingDirection, $searchType: SupportPageSectionSearch) {
  accounts(limit: $limit, page: 1) {
    items {
      id
      title
      supportPages {
        id
        title
      }
    }
  }
  supportPage(id: $supportPageid) {
    sections (search: $search, limit: $limit, page: $page, sortBy: $sortBy, sortDirection: $sortDirection, searchType: $searchType) {
      currentPage
      totalPages
      previousPage
      nextPage
      items{
        id
        content
        sectionType
        mediaType
        mediaUrl
        sorting
        createdAt
        updatedAt
      }
    }
  }
}
 
`

export const SUPPORT_PAGE_QUESTION_QUERY = gql`
query SupportPageQuestionQuery($questionId: ID) {
  supportPageQuestion(id: $questionId) {
    question
    answer
    topic
    mediaType
    mediaUrl
    createdAt
    updatedAt
  }
}
`

export const SUPPORT_PAGE_SECTION_QUERY = gql`
query SupportPageSectionQuery($sectionId: ID) {
  supportPageSection(id: $sectionId) {
    id
    content
    sectionType
    sorting
    mediaUrl
    mediaType
    createdAt
    updatedAt
  }
}
`

export const UPDATE_SUPPORT_PAGE_QUESTION_MUTATION = gql`
mutation UpdateQuestion($id: ID!, $topic:String, $question:String, $answer:String, $mediaUrl: String, $mediaType: String) {
  updateSupportPageQuestion(supportPageQuestionId: $id, topic: $topic, question:$question, answer:$answer, mediaUrl:$mediaUrl, mediaType: $mediaType){
    id
  	topic
  	answer
    question,
    mediaUrl,
    mediaType,
  }
}
`
export const UPDATE_SUPPORT_PAGE_SECTION_MUTATION = gql`
mutation UpdateSection($id: ID!, $content:String, $sectionType:String, $sorting:Int, $mediaUrl: String, $mediaType: String) {
  updateSupportPageSection(supportPageSectionId: $id, content: $content, sectionType:$sectionType, sorting:$sorting, mediaUrl:$mediaUrl, mediaType: $mediaType){
    id
    content
    sectionType
    sorting
    mediaUrl
    mediaType
  }
}
`

export const CREATE_SUPPORT_PAGE_QUESTION_MUTATION = gql`
mutation createQuestion ($id:ID!, $question: String)
{createSupportPageQuestion(supportPageId: $id, question: $question)
  {
    id, question
  }
}
`

export const CREATE_SUPPORT_PAGE_SECTION_MUTATION = gql`
mutation createSection ($id:ID!, $sectionType: String)
{createSupportPageSection(supportPageId: $id, sectionType: $sectionType)
  {
    id, sectionType
  }
}
`

export const DELETE_SUPPORT_PAGE_QUESTION_MUTATION = gql`
mutation deleteQuestion($id: ID!) {
  deleteSupportPageQuestion(supportPageQuestionId: $id) {
    id
    question
  }
}
`

export const DELETE_SUPPORT_PAGE_SECTION_MUTATION = gql`
mutation deleteSection($id: ID!) {
  deleteSupportPageSection(supportPageSectionId: $id) {
    id
    content
  }
}

`

