import React from 'react'
import {timeDistanceInWords} from '../helpers/TimeInWords'
import { Link } from 'react-router-dom'
import { VideoList } from '../custom_types'

const LatestVideos : React.FC<VideoList> = ({videos}) => {
  return (
    <div className="w-full mb-6 lg:mb-0 lg:w-1/2 px-4 flex flex-col">
      <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
        <div className="border-b">
          <div className="flex justify-between px-6 -mb-px">
            <h3 className="text-blue-600 py-4 font-normal text-lg">Latest Uploaded Videos</h3>
          </div>
        </div>
        {videos.map( video => {
          return (
            <div className="flex-grow flex px-6 py-6 text-grey-700 items-center border-b -mx-4" key={video.id}>
              <div className="w-1/5 px-4">
                <img className="text-right text-grey" src={video.publicPoster} />
              </div>
              <div className="w-3/5 px-4 flex items-center">
                <span className="text-lg">
                  <Link to={`/dashboard/videos/${video.id}`}>
                    {video.title}
                  </Link>
                  <span className="text-sm ml-1">(
                    <Link to={`/dashboard/channels/${video.channel.id}`}>
                      {video.channel.title}
                    </Link>
                    )</span></span>
              </div>
              <div className="w-1/5 px-4">
                <div className="text-right text-grey">
                  {timeDistanceInWords(video.createdAt)}
                </div>
              </div>
            </div>
          )
        })}
        <div className="px-6 py-4">
          <div className="text-center text-grey">
            Video Count : N/A
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestVideos
