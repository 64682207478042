import React, { useState, useRef } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { Routes, Route, Outlet, NavLink, Link, useParams } from 'react-router-dom'
import Loading from '../../../../loading'
import Pagination from '../../../../../Pagination'
import { timeDistanceInWords } from '../../../../../helpers/TimeInWords'
import { SUPPORT_PAGE_QUESTION_QUERY, SUPPORT_PAGE_SECTION_QUERY, UPDATE_SUPPORT_PAGE_SECTION_MUTATION } from '../Query'
import { AssetUpload, VideoUpload } from "../../../../Video/Upload";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'




const Section = () => {

    const onUploadIcon = (url, fileType) => {

        //if i use the setField method here, only one property gets added. Probably something to do with the async nature of react. Correct me here Claus if there is a better alternative
        setSectionParams(Section => ({ ...Section, mediaType: fileType }))
        setSectionParams(Section => ({ ...Section, mediaUrl: url }))
        setUploadIcon(false);

    };

    //Gets the channelId from the url
    const { sectionId } = useParams();

    console.log("sectionId: " + sectionId)

    //useState for a channelObject that later will be used to update the channels values
    const [Section, setSectionParams] = useState(undefined as any)
    const [uploadAsset, setUploadIcon] = useState(false);
    const [asset, setIcon] = useState(undefined);



    //NOTE: This is copied from javascript/dashboard/video/edit.tsx
    //make generic method later
    /**
     * Method for changing and updating channel paramteres
     * @param fieldName The field that will be changed
     * @param value 
     * @returns 
     */
    const setField = (fieldName: string, value?: any) => {

        if (value === undefined) {
            return function (value: any) {
                setSectionParams(
                    {
                        ...Section,
                        [fieldName]: value
                    }
                )
            }
        }
        else {
            setSectionParams(
                {
                    ...Section,
                    [fieldName]: value
                }
            )
        }
    }

    //load data from the database with the gql query
    const { loading, data, error, refetch } = useQuery(SUPPORT_PAGE_SECTION_QUERY, { variables: { "sectionId": sectionId }, fetchPolicy: 'no-cache' });
    //mutation for saving the changes
    const [saveChanges, saveResult] = useMutation(UPDATE_SUPPORT_PAGE_SECTION_MUTATION)
    const activePreview = Section?.mediaUrl || data?.supportPageSection?.mediaUrl || undefined
    const activePreviewType = Section?.mediaType || data?.supportPageSection?.mediaType || undefined






    const save = () => {
        const vars = {};
        vars['id'] = sectionId;

        //checks if the properties exist before assigning them
        if (Section?.sectionType != undefined) {
            vars['sectionType'] = Section.sectionType;
        }

        if (Section?.content != undefined) {
            vars['content'] = Section.content;
        }

        if (Section?.sorting != undefined) {
            vars['sorting'] = Number(Section.sorting);
        }

        if (Section?.mediaUrl != undefined) {
            vars['mediaUrl'] = Section.mediaUrl;
        }

        if (Section?.mediaType != undefined) {
            vars['mediaType'] = Section.mediaType;
        }



        const result = saveChanges({ variables: vars })



        result.then((done) => {

            console.log(data.supportPageSection)

        }).catch(v => console.error(v)).finally(() => {
            //when its done writing to the database, refetch the data
            refetch().then(({ data }) => {
                console.log('Refetch is done!', data);
                setSectionParams(data.supportPageSection);
            });
        });
    }

    console.log(asset)




    console.log(data)
    console.log(data?.supportPageSection?.mediaUrl)
    console.log()

    //check if the data is loaded
    if (loading && !Section) return <Loading />
    if (error) {
        console.log(error)
        return <div>Error</div>
    }
    return (
        <div>
            <div>

                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                    <h1>Edit Section</h1>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                            Section type
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                                <input className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={data?.supportPageSection?.sectionType} onChange={(e) => { setField("sectionType", e.target.value) }} />
                            </div>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className=" text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 ">
                            Content
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                                <textarea rows={10} className="form-textarea w-full justify-center transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={data?.supportPageSection?.content} onChange={(e) => { setField("content", e.target.value) }} ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">

                        <label className=" text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 ">
                            Sorting
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                                <input type="number" className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" defaultValue={data?.supportPageSection?.sorting} onChange={(e) => { setField("sorting", e.target.value) }} />
                            </div>
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">

                        <label className=" text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2 ">
                            Asset
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="rounded-md shadow-sm">
                                <AssetPreview url={activePreview ? activePreview : data?.supportPageSection?.mediaUrl} fileType={data?.supportPageSection?.mediaType}></AssetPreview>
                                {uploadAsset ? (
                                    <>
                                        <AssetUpload onUpload={onUploadIcon} />
                                        <span
                                            onClick={() => setUploadIcon(!uploadAsset)}
                                            className="p-4 m-4 text-sm"
                                        >
                                            cancel
                                        </span>
                                    </>
                                ) : (
                                    <button onClick={() => setUploadIcon(!uploadAsset)}>


                                        <a key="download-original" target="_blank" className="  text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2"><FontAwesomeIcon icon={faUpload} className="mr-2" />Upload new </a>


                                    </button>

                                )}
                                {activePreview || data?.supportPageSection?.mediaUrl ? (


                                    <a key="download-original" target="_blank" href={Section?.mediaUrl ? Section?.mediaUrl : data?.supportPageSection?.mediaUrl} className="  text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2"><FontAwesomeIcon icon={faUpload} className="mr-2" />Download {Section?.mediaType ? Section?.mediaType?.split('/')[1] : data?.supportPageSection?.mediaType?.split('/')[1]} </a>

                                ) : (
                                    <span>No Asset</span>
                                )}
                                {/* <a key="download-original" target="_blank" href={data?.supportPageSection?.mediaUrl} className="  text-white text-sm bg-brand-500 hover:bg-blue-700 text-sm py-3 px-4 font-sans tracking-wide uppercase font-bold w-64 m-2"><FontAwesomeIcon icon={faUpload} className="mr-2" />Download { Section?.mediaType? Section?.mediaType?.split('/')[1] : data?.supportPageSection?.mediaType?.split('/')[1]} </a> */}
                                <span className='block py-5'>Asset Url: {Section?.mediaUrl ? Section?.mediaUrl : data?.supportPageSection?.mediaUrl}</span>

                            </div>
                        </div>
                    </div>

                    <button className="mt-8 bg-brand-500 hover:bg-brand-300 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={save}> {saveResult && saveResult.loading ? "Saving...." : "Save"}</button>
                </div>
            </div>

        </div>
    )
}


const AssetPreview = ({ url, fileType }) => {
    if (fileType?.includes("image")) {
        return (<img src={url} className="w-128 h-128 p-2 mr-2" />)
    }
    else {
        return <span></span>
    }

}


export default Section
