import React, { useState } from 'react'
import { Routes, Route,  Outlet, NavLink } from 'react-router-dom'


const ToolDashboard = () => {

  return (
        <div className="flex flex-wrap">
            <div className="w-full mb-6 lg:mb-0 px-4 flex flex-col">
                <div className="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">
                </div>
            </div>
        </div>
      )
}


const Tools = () => {
  return (
        <div>
            <h1 className="text-3xl text-black pb-4">Fliva Admin Tools</h1>
            <div className="flex flex-wrap">
                <div className="flex-grow w-full mx-auto">
                  <Routes>
                    <Route index element={<ToolDashboard  />} />
                  </Routes>
                <Outlet />
                </div>
            </div>
        </div>
  )
}



export default Tools
