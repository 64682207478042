import mixpanel from 'mixpanel-browser';
mixpanel.init('d8cc25bc7872f973209f7c56f1e93ec0', { "api_host": "https://api-eu.mixpanel.com" }, "");

let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id:any) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id:any) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name : string, props?: any ) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props:any) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
