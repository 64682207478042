import React, { useRef } from 'react'
import { Query, useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { Route, Link, useParams } from 'react-router-dom'
import {timeDistanceInWords} from '../../helpers/TimeInWords'
import Loading from '../loading'
import { Mixpanel } from '../../Common/Mixpanel'
import Versions from '../Versions'

var { DropkiqUI } = require('dropkiq-ui');

// import "@fortawesome/fontawesome-free/css/all.css";

import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-json";

import Editor from './../../player-builder/PlayerEditor'

export const TEMPLATESHOW_QUERY = gql`
query TemplateShow($id:ID!) {
    template(id:$id) {
        id
        name
        createdAt
        xml
        settings
        engine
    		versions {
          	id,
            event,
            createdAt,
            whodunnit {
                id,
                username
            }, 
            item {
                name
            }
            objectModelNil
        }
    }
}
`

export const SAVE_TEMPLATE_MUTATION = gql`
mutation saveTemplate($id:String!, $name:String, $xml:String, $settings:JSON, $engine:String) {
    updateTemplate(id:$id, name:$name, xml:$xml, settings:$settings, engine: $engine) {
    id
    name
    xml
    settings
    engine
  }
}
`


const ROLL_BACK_TEMPLATE_MUTATION = gql`
mutation rollBackTemplate($templateId: ID!, $versionId: ID!){
    rollBackTemplate(templateId: $templateId, versionId: $versionId) {
        name
    }
}
`

const Show = () => {
    let params = useParams()
    const vars = {id: params.templateId}
    let localData = useRef({});
    const updateData = (field, value) => {
        localData.current[field] = value
    }
    const updateXml = (value) => {
        updateData('xml', value)
    }

    const updateSettings = (value) => {
        updateData('settings', value)
    }

    const updateName = (value) => {
        updateData('name', value)
    }

    const updateEngine = (value) => {
        updateData('engine', value)
    }

    const [saveTemplate, { data }] = useMutation(SAVE_TEMPLATE_MUTATION);

    const save = () => {
        const vars = {
            id: params.templateId,
            name: localData.current['name'],
            xml: localData.current['xml'],
            settings: localData.current['settings'],
            engine: localData.current['engine'],

        }
        Mixpanel.track("Saving template", {id: params.templateId, name: vars.name}) 
        console.log(vars)
        const result = saveTemplate({ variables: vars })
        console.log(result)
        window.res = result
        result.then((done) => {
            Mixpanel.track("Saved template", {id: params.templateId, name: vars.name}) 
            console.log(done)
        }).catch(v => console.error(v))
    }
    return (
        <Query query={TEMPLATESHOW_QUERY} variables={vars}>
            {({ loading, error, data, subscribeToMore }) => {
                if (loading) return <Loading />
                    if (error) {
                        console.log(error)
                        return <div>Error</div>
                    }
                if(data) {
                    updateXml(data.template.xml)
                    updateSettings(data.template.settings)
                    updateName(data.template.name)
                    updateEngine(data.template.engine)
                }
                return (
                    <div className="px-8">
                        <Versions versions={data.template.versions} ROLL_BACK_MUTATION={ROLL_BACK_TEMPLATE_MUTATION} itemID={params.templateId}></Versions>
                        <div className="flex w-full p-2 bg-white border border-slate-300 rounded-t-md justify-between items-center">
                            <div>
                                <label className="mx-2 text-slate-400">Title:</label>
                                <input defaultValue={data.template.name} className="px-2 py-1 border border-slate-400 rounded" onChange={(e) => updateName(e.target.value)}/>
                            </div>
                            <div>
                                <label className="mx-2 text-slate-400">Template engine:</label>
                                <select defaultValue={data.template.engine} className="px-2 py-1 border border-slate-400 rounded" onChange={(e) => updateEngine(e.target.value)}>
                                    <option value="handlebars">Handlebars</option>
                                    <option value="liquid">Liquid</option>
                                </select>
                            </div>
                            <button onClick={save} className="bg-brand-500 hover:bg-brand-400 active:bg-brand-600 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Save</button>
                        </div>
                        <Editor mode="xml" update={updateXml} value={data.template.xml} height="600px" liquidScope={data.template.engine == 'liquid' ? JSON.parse(localData.current['settings']) : null} />
                        <hr className="my-4" />
                        <Editor mode="json" update={updateSettings} value={JSON.stringify(JSON.parse(data.template.settings),  null, '\t')} height="100px" />
                        <hr className="my-4" />
                    </div>
                )}}
                    </Query>

            )
}


export default Show
